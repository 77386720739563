import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import { ValidationProvider } from 'vee-validate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'

Vue.config.productionTip = false

library.add(faCircleArrowRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationProvider', ValidationProvider)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
