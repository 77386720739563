import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      name: 'landing',
      component: () => import('@/components/Landing')
    },
    {
      path: '/forms/:typeRequest',
      name: 'forms',
      component: () => import('@/components/Denuncia')
    },
    {
      path: '/resultado/:typeRequest/:success/:message',
      name: 'resultado',
      component: () => import('@/components/Resultado')
    },
    {
      path: '/*',
      redirect: '/home'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
